import React from "react";
import Layout from "../components/Layout";
import { graphql, Link } from "gatsby";
import SEO from "../components/SEO";

const TagsPage = ({ data }) => {
  const tags = data.allMdx.edges;
  return (
    <Layout>
      <SEO title="Tags" />
      {tags.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article key={node.fields.slug}>
            <header>
              <h3>
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small>{node.frontmatter.date}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        );
      })}
    </Layout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___datePublished], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            datePublished(formatString: "YYYY-MM-DD HH:mm:ss")
            title
            description
          }
        }
      }
    }
  }
`;
